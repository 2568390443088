import React from "react";

const Darkimgs = () => {
  return (
    <div className="darkss d-flex justify-content-between flex-wrap pr-5">
      <div className="remov">
        <img src="/images/mm1.png" alt="" />
      </div>
      <div className="remov">
        <img src="/images/mm2.png" alt="" />
      </div>
      <div className="remov">
        <img src="/images/code.png" alt="" />
      </div>
      <div className="remov">
        <img src="/images/remov.png" alt="" />
      </div>
    </div>
  );
};

export default Darkimgs;
