import React, { useState } from "react";
import "../Navbar.css";
import { Link } from "react-router-dom";
const Navbar = ({ moveToDiv }) => {
  const [login] = useState(localStorage.getItem("token") ? true : false);
  const [isOpen, setIsOpen] = useState(false);
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };
  const clearData = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
  };
  const getValue = (move, tab) => {
    toggleTab(tab);
    moveToDiv(move);
  };

  return (
    <>
      <div className="main_nav">
        <div className="Navbar">
          <Link to="/">
            <img className="intestlogo" src="/images/logo.svg" alt="" />
          </Link>
          <div className={`nav-items ${isOpen && "open"}`}>
            <Link
              onClick={() => getValue("home", 1)}
              to="/"
              className={toggleState === 1 ? "statebtn1" : "statebtn"}
            >
              Home
            </Link>
            <Link
              className={toggleState === 2 ? "statebtn1" : "statebtn"}
              onClick={() => getValue("about", 2)}
              to="/"
            >
              About Us
              <img className="arrow" src="/images/dropimg.svg" alt="" />
            </Link>
            <Link
              className={toggleState === 3 ? "statebtn1" : "statebtn"}
              onClick={() => getValue("webfeed", 3)}
              to="/"
            >
              Web Feed
            </Link>
            <Link
              className={toggleState === 4 ? "statebtn1" : "statebtn"}
              onClick={() => getValue("contact", 4)}
              to="/"
            >
              Contact Us
            </Link>
            {!login ? (
              <Link className="bttn" to="/login">
                <button className="creat_accout">Login</button>
              </Link>
            ) : (
              <Link className="bttn" to="/login">
                <button onClick={clearData} className="creat_accout">
                  Logout
                </button>
              </Link>
            )}
          </div>
          <div
            className={`nav-toggle ${isOpen && "open"}`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className="bar"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
