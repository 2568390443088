import React from "react";
import CarouselSlider from "./CarouselSlider";
export const Keepingup = () => {
  return (
    <div>
      <p className="keeping_up_tittle">Keeping up with ITTN</p>
      <CarouselSlider />
    </div>
  );
};
